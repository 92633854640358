import { Link } from "@nextui-org/react";

export const Home = () => (
  <main className="p-4">
    <div className="mb-8">
      <h1 className="text-2xl">Oyster Web Components Demo</h1>
      <p>
        This app is a place for us to create client demos and test the
        production npm package.
      </p>
    </div>

    <h2 className="text-lg font-semibold">Client Demos</h2>
    <ul className="list-disc list-inside mb-8">
      <li>
        {/* bit of a hack to set the theme for this demo lol */}
        <Link href="/adp">ADP Demo</Link>
      </li>
    </ul>

    <h2 className="text-lg font-semibold">Web Components via npm</h2>
    <ul className="list-disc list-inside">
      <li>
        <Link href="/components/cost-calculator">Cost Calculator</Link>
      </li>
      <li>
        <Link href="/components/hire-button">Hire Button</Link>
      </li>
      <li>
        <Link href="/components/hire-form">Hire Form</Link>
      </li>
      <li>
        <Link href="/components/hire-flow">Hire Flow</Link>
      </li>
      <li>
        <Link href="/components/salary-insights">Salary Insights</Link>
      </li>
      <li>
        <Link href="/components/expense-form">Expense Form</Link>
      </li>
    </ul>
  </main>
);
